import type { DecoratedProduct } from '~/utils/shop/DecoratedProduct';

type ProductDetailLinkFactory = (product: DecoratedProduct) => string;

/**
 * Provides a typed abstraction to the createProductLink plugin provided
 * by the web app. Instead of dif
 */
export function useCreateProductDetailLink(): ProductDetailLinkFactory {
  const { $createProductDetailLink = () => '#' } = useNuxtApp();

  // DecoratedProduct type interferes in build, although it is the same...
  return $createProductDetailLink;
}
